<template>
	<div>
		<b-alert class="text-left" variant="danger" :show="error_message != null" v-html="error_message"></b-alert>
		<b-alert class="text-left" variant="success" :show="success_message != null" v-html="success_message"></b-alert>
        <b-alert class="text-left" variant="warning" :show="warning_message != null" v-html="warning_message"></b-alert>
	</div>
</template>

<script>
export default {
	name: "FormAlert",
	props: {
		error_message: null,
		success_message: null,
        warning_message: null
	},
};
</script>

import ApiService from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";

//action type
export const GET_SYS_SETTINGS = "sysSettings";
export const GET_LOCALE = "locale";
export const GET_LOGIN_TITLE = "loginTitle";

//mutation types
export const SET_SYS_SETTINGS = "setSettings";
export const SET_LOCALE = "setLocale";
export const SET_LOGIN_TITLE = "setLoginTitle";

const state = {
	settings: {
		quotation: {
			skip_confirm_lcl_condition: 0,
			skip_confirm_fcl_condition: 0
		},
		defaultCurrency: null
	},
	locales: [],
	loginTitle: null
};

const getters = {
	currentSettings(state) {
		return state.sys_settings;
	},
	currentLocale(state) {
		return state.locales;
	},
	loginTitle() {
		return state.loginTitle;
	}
};

const actions = {
	[GET_SYS_SETTINGS](context) {
		return new Promise((resolve) => {
			ApiService.get("settings/sys-setting").then((response) => {
				let data = response.data;

				if (data.code == 200) {
					let result = data.result;
					let newData = {};
					for (let group in result) {
						if (group == "defaultCurrency") {
							newData[group] = result[group];
							continue;
						} else {
							newData[group] = {};
						}

						for (let key in result[group]) {
							let record = result[group][key];
							if (record["serialized"] != null && record["serialized"] == "2") {
								newData[group][key] = JSON.parse(record.value);
							} else {
								newData[group][key] = record.value;
							}
						}
					}
					context.commit(SET_SYS_SETTINGS, newData);
					resolve(data);
				}
			});
		});
	},
	[GET_LOCALE](context) {
		return new Promise((resolve) => {
			ApiService.get("locale/list").then((response) => {
				let data = response.data;
				if (data.code == 200) {
					context.commit(SET_LOCALE, data.result);
					resolve(data);
				}
			});
		});
	},
	[GET_LOGIN_TITLE](context) {
		return new Promise(() => {
			context.commit(SET_LOGIN_TITLE, i18n.t("AUTH.LOGIN.TITLE") + "12333");
			//resolve(data);
		});
	}
};

const mutations = {
	[SET_SYS_SETTINGS](state, settings) {
		state.sys_settings = settings;
	},
	[SET_LOCALE](state, localeList) {
		state.locales = localeList;
	},
	[SET_LOGIN_TITLE](state, title) {
		state.loginTitle = title;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};

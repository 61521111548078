<template>
	<div>
		<b-card no-body>
			<b-card-header id="gsumary_card_header">
				<b-row>
					<b-col>
						<h4 class="float-left" v-text="summaryTitle"></h4>
						<a class="ml-2" :aria-controls="controlsName" @click="collapseVisible = !collapseVisible" v-if="!hideSearchForm">
							<span class="when-open">
								<i class="fa fa-chevron-down" aria-hidden="true" v-if="!collapseVisible"></i>
							</span>
							<span class="when-closed">
								<i class="fa fa-chevron-up" aria-hidden="true" v-if="collapseVisible"></i>
							</span>
						</a>
					</b-col>
					<b-col align="right" v-if="createRoute != null">
						<slot name="otherHeaderButton"></slot>
						<b-button :to="createRoute" variant="success" class="float-right" size="sm" v-if="hasCreatePermission">
							<b-icon-plus-square-fill v-if="buttonName == null"></b-icon-plus-square-fill>
							<span :class="buttonName == null ? `ml-1` : ``" v-text="buttonName != null ? buttonName : $t('COMMON.BTN_CREATE', { btn_name: moduleName })"></span>
						</b-button>
						<slot name="cancelButton"></slot>
					</b-col>
					<b-col align="right" v-if="createRoute == null">
						<slot name="headerButton"></slot>
					</b-col>
				</b-row>
				<b-collapse :id="controlsName" v-model="collapseVisible" class="mt-3" v-if="!hideSearchForm" @hidden="hiddenSize" @shown="showSize">
					<slot name="searchFormSlot"></slot>
				</b-collapse>
			</b-card-header>
			<b-card-body :style="`overflow: auto; height: ${$Common.summaryBodyHeightHandle()}px !important;`">
				<div class="tbl_action">
					<slot name="summaryButtonSlot"></slot>
				</div>

				<slot name="dataSlot"></slot>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name: "GimSummaryPage",
	props: {
		summaryTitle: String,
		createRoute: null,
		hasCreatePermission: false,
		moduleName: null,
		controlsName: null,
		hideSearchForm: false,
		buttonName: null,
		page: null
	},
	data() {
		return {
			collapseVisible: true
		};
	},
	methods: {
		hiddenSize() {
			const allocateHeight = this.$Common.dataTableHeightHandle();
			if (document.querySelector("div.vdatable > div.v-data-table__wrapper") !== null) document.querySelector("div.vdatable > div.v-data-table__wrapper").style.height = `${allocateHeight}px`;
		},
		showSize() {
			const allocateHeight = this.$Common.dataTableHeightHandle();
			if (document.querySelector("div.vdatable > div.v-data-table__wrapper") !== null) document.querySelector("div.vdatable > div.v-data-table__wrapper").style.height = `${allocateHeight}px`;
		}
	}
};
</script>

import ApiService from "@/core/services/api.service";

const getStateList = function (cid = null) {
	return ApiService.post("states/active", { cid: cid })
		.then(response => {
			if (response.status == "200") {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(error => {
			alert(error);
		});
};

const getActiveCountries = function () {
	return ApiService.get("countries/active")
		.then(response => {
			if (response.status == "200") {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(error => {			
			return [];
		});
};

const getCityList = function (cid = null, sid = null) {
	return ApiService.post("cities/active", { cid: cid, sid: sid })
		.then(response => {
			if (response.status == "200") {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(error => {
			alert(error);
		});
};

const getDistrict = function(cid = null, sid = null, city_id = null) {
    return ApiService.post("district/active", { country_id: cid, state_id: sid, city_id: city_id })
        .then(response => {
            if (response.status == "200") {
                return response.data.result;
            } else {
                return [];
            }
        });
};

const getCallingCode = function () {
	return ApiService.get("countries/phone-code")
		.then(response => {
			if (response.status == "200") {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(error => {
			alert(error);
			return [];
		});
};

const getPostCode = function (country_id, state_id, city_id, keyword, keyword1) {
	return ApiService.post("postcode/active", {
		country_id: country_id,
		state_id: state_id,
		city_id: city_id,
		keyword: keyword,
		keyword1: keyword1
	})
		.then(response => {
			if (response.status == "200") {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(error => {
			alert(error);
			return [];
		});
};

const getAreaList = function (country_id = null) {
    return ApiService.post("area/selection", { country_id: country_id }).then(response => {
        if (response.status == "200") {
            return response.data.result;
        } else {
            return [];
        }
    })
        .catch(error => {
            alert(error);
            return [];
        });
};

const getCountryCallingCode = function(country_id) {
    return ApiService.post("countries/calling-code", { country_id: country_id }).then(response => {
        if (response.data.code == "200") {
            return response.data.result;
        } else {
            return "+60";
        }
    })
}

export default {
	getStateList,
	getActiveCountries,
	getCityList,
	getCallingCode,
	getPostCode,
	getAreaList,
	getDistrict,
	getCountryCallingCode
};
<template>
	<b-row align-v="center">
		<slot name="search"></slot>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('agent_id')"
			v-if="!hide('agent_id')"
		>
			<b-form-group
				:class="getRequiredFields('agent_id')"
				:label="$t('COMMON.LBL_AGENT')"
			>
				<selectField
					v-if="multiple_agent"
					:data="searchForm.agent_ids"
					:selection="agent_selection"
					@update="
						(v) => {
							searchForm.agent_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					:custom_selection="1"
					@mouseenter="getAgentSelection"
				></selectField>
				<selectField
					v-if="!multiple_agent"
					:data="searchForm.agent_id"
					:selection="agent_selection"
					@update="
						(v) => {
							searchForm.agent_id = v;
						}
					"
					:clearable="true"
					:custom_selection="1"
					@focus="getAgentSelection"
				></selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="4"
			lg="3"
			xl="2"
			:order="getFieldOrder('customer_id')"
			v-if="!hide('customer_id')"
		>
			<b-form-group
				:class="getRequiredFields('customer_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_CUSTOMER_NAME')"
			>
				<selectField
					:data="searchForm.customer_ids"
					:selection="customerSelection"
					@update="
						(v) => {
							searchForm.customer_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					:value_key="`customer_agent_id`"
					:custom_selection="1"
					@focus="getCustomerCompanySelection"
					v-if="use_marking_selection == 1"
				></selectField>
				<b-form-input
					v-if="use_marking_selection == 0"
					size="sm"
					:placeholder="$Common.placeHolderHandle($t('COMMON.LBL_CUSTOMER_NAME'))"
					v-model="searchForm.customer_name"
				></b-form-input>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="4"
			lg="3"
			xl="2"
			:order="getFieldOrder('customer_marking_id')"
			v-if="!hide('customer_marking_id')"
		>
			<b-form-group
				:class="getRequiredFields('customer_marking_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_MARKING')"
			>
				<selectField
					:data="searchForm.customer_marking_ids"
					:selection="markingSelection"
					:value_key="`customer_marking_agent_id`"
					@update="
						(v) => {
							searchForm.customer_marking_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@mouseenter="getMarkingSelection"
					:custom_selection="1"
					v-if="use_marking_selection == 1"
				></selectField>
				<b-form-input
					v-if="use_marking_selection == 0"
					size="sm"
					:placeholder="$Common.placeHolderHandle($t('COMMON.SEARCHFORM.LBL_MARKING'))"
					v-model="searchForm.marking"
					:formatter="
						(v) => {
							return v.toUpperCase();
						}
					"
					lazy-formatter
					@keydown.enter.prevent
				></b-form-input>
			</b-form-group>
		</b-col>
		<b-col
			:order="getFieldOrder('delivery_address_id')"
			v-if="!hide('delivery_address')"
			sm="12"
			md="4"
			lg="3"
			xl="2"
		>
			<b-form-group
				:class="getRequiredFields('delivery_address_id')"
				:label="$t('COMMON.LBL_DELIVERY_ID')"
			>
				<selectField
					:data="searchForm.delivery_address_ids"
					:selection="delivery_address_selection"
					@update="
						(v) => {
							searchForm.delivery_address_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getDeliveryAddressSelection()"
					:text_key="`full_delivery_id`"
					:value_key="`delivery_address_agent_id`"
					:field_type="`delivery_address`"
					:wms="1"
					:custom_selection="1"
				>
				</selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('msm_id')"
			v-if="!hide('msm_id')"
		>
			<b-form-group
				:class="getRequiredFields('msm_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_MAIN_SALESMAN')"
			>
				<selectField
					:data="searchForm.msm_ids"
					:selection="msmSelection"
					:value_key="`msm_agent_id`"
					@update="
						(v) => {
							searchForm.msm_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getMsmSelection"
					:custom_selection="1"
				></selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('sm_id')"
			v-if="!hide('sm_id')"
		>
			<b-form-group
				:class="getRequiredFields('sm_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_SALESMAN')"
			>
				<selectField
					:data="searchForm.sm_ids"
					:selection="smSelection"
					:value_key="`sm_agent_id`"
					@update="
						(v) => {
							searchForm.sm_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					@focus="getSmSelection"
				></selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('company_id')"
			v-if="!hide('company_id')"
		>
			<b-form-group
				:class="getRequiredFields('company_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_COMPANY')"
			>
				<selectField
					:data="searchForm.company_ids"
					:selection="companySelection"
					:value_key="`company_agent_id`"
					@update="
						(v) => {
							searchForm.company_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					:custom_selection="1"
					@focus="getCompanySelection"
				></selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('company_group_id')"
			v-if="!hide('company_group_id')"
		>
			<b-form-group
				:class="getRequiredFields('company_group_id')"
				:label="$t('COMMON.SEARCHFORM.LBL_COMPANY_GROUP')"
			>
				<selectField
					:data="searchForm.company_group_ids"
					:selection="companyGroupSelection"
					@update="
						(v) => {
							searchForm.company_group_ids = v;
						}
					"
					:value_key="`company_group_agent_id`"
					:multiple="true"
					:clearable="true"
					:custom_selection="1"
					@focus="getCompanyGroup"
				></selectField>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="3"
			lg="3"
			xl="2"
			:order="getFieldOrder('warehouse_id')"
			v-if="!hide('warehouse_id')"
		>
			<b-form-group
				:class="getRequiredFields('warehouse_id')"
				:label="$t('COMMON.LBL_WAREHOUSE')"
			>
				<selectField
					v-if="!multiple_warehouse"
					:data="searchForm.warehouse_id"
					:selection="warehouseSelection"
					:classname="
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid) &&
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid.warehouse_id)
							? $Common.setRequiredErrorBorder(searchFormValid.warehouse_id.state, 0, 1)
							: ``
					"
					@update="
						(v) => {
							searchForm.warehouse_id = v;
						}
					"
					:clearable="true"
					:custom_selection="1"
					@focus="getWarehouseSelection()"
				></selectField>
				<selectField
					v-if="multiple_warehouse"
					:data="searchForm.warehouse_ids"
					:selection="warehouseSelection"
					@update="
						(v) => {
							searchForm.warehouse_ids = v;
						}
					"
					:multiple="true"
					:clearable="true"
					:custom_selection="1"
					:classname="
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid) &&
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid.warehouse_id)
							? $Common.setRequiredErrorBorder(searchFormValid.warehouse_id.state, 0, 1)
							: ``
					"
					@focus="getWarehouseSelection()"
				></selectField>
				<template
					v-if="
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid) &&
						!$CoreSettingsJs.emptyObjectValidation(searchFormValid.warehouse_id)
					"
				>
					<b-form-invalid-feedback
						:state="searchFormValid.warehouse_id.state"
						v-text="searchFormValid.warehouse_id.msg"
					></b-form-invalid-feedback>
				</template>
			</b-form-group>
		</b-col>

		<slot name="search_center"></slot>
		<b-col
			sm="12"
			md="2"
			lg="3"
			xl="2"
			:order="getFieldOrder('status')"
			v-if="!hide('status')"
		>
			<b-form-group :class="getRequiredFields('status')" :label="$t('COMMON.LBL_STATUS')">
				<selectField
					:data="searchForm.status"
					:selection="$CoreJs.statusSelection"
					@update="
						(v) => {
							searchForm.status = v;
						}
					"
					:value_key="`item`"
					:text_key="`name`"
				></selectField>
			</b-form-group>
		</b-col>
		<searchButton
			class="align-self-center"
			:order="getFieldOrder('search_button')"
			v-if="!hide('search_button')"
		></searchButton>
	</b-row>
</template>

<script>
import searchButton from "@/view/content/form/SearchFormButton.vue";
export default {
	name: "SearchFormStandardFilter",
	props: {
		searchForm: null,
		multiple_agent: {
			type: Boolean,
			default() {
				return true;
			},
		},
		multiple_warehouse: {
			type: Boolean,
			default() {
				return false;
			},
		},
		use_marking_selection: {
			type: Number,
			default: 1,
		},
		hideField: {
			type: Array,
			default() {
				return [];
			},
		},
		sortField: {
			type: Object,
			default() {
				return {};
			},
		},
		master_page: {
			type: Boolean,
			default() {
				return false;
			},
		},
		required_fields: {
			type: Array,
			default() {
				return [];
			},
		},
		searchFormValid: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		searchButton,
	},
	data() {
		return {
			msmSelection: [],
			smSelection: [],
			companySelection: [],
			companyGroupSelection: [],
			markingSelection: [],
			customerSelection: [],
			agent_selection: [],
			delivery_address_selection: [],
			warehouseSelection: [],
		};
	},
	computed: {
		computed_msm_id() {
			return this.searchForm.msm_ids.map((x) => x.split("-")[0]);
		},
	},
	methods: {
		getRequiredFields(field_name) {
			return !this.$CoreSettingsJs.emptyObjectValidation(this.required_fields) &&
				this.required_fields.includes(field_name)
				? `required-fields`
				: ``;
		},
		getFieldOrder(field_name) {
			return !this.$CoreSettingsJs.emptyObjectValidation(this.sortField) &&
				!this.$CoreSettingsJs.emptyObjectValidation(this.sortField[field_name])
				? parseInt(this.sortField[field_name])
				: 0;
		},
		hide(field_name) {
			return (!this.$CoreSettingsJs.emptyObjectValidation(this.hideField) &&
				this.hideField.includes(field_name)) ||
				(this.master_page &&
					!["status", "agent_id", "msm_id", "search_button", "warehouse_id"].includes(
						field_name
					))
				? true
				: false;
		},
		async getCustomerCompanySelection() {
			this.customerSelection = await this.$CoreJs.customerSelection({
				agent_ids: this.searchForm.agent_ids,
			});
		},
		async getMarkingSelection() {
			this.markingSelection = await this.$CoreJs.markingSelection({
				agent_ids: this.searchForm.agent_ids,
				customer_ids: this.searchForm.customer_ids,
			});
		},
		async getMsmSelection() {
			this.msmSelection = await this.$CoreJs.mainSalesmanSelection({
				agent_ids: this.searchForm.agent_ids,
			});
		},
		async getCompanySelection() {
			this.companySelection = await this.$MasterDataJs.companySelection({
				agent_ids: this.searchForm.agent_ids,
			});
		},
		async getCompanyGroup() {
			this.companyGroupSelection = await this.$CoreJs.companyGroupSelection({
				company_ids: this.searchForm.company_ids,
				agent_ids: this.searchForm.agent_ids,
			});
		},
		async getSmSelection() {
			this.smSelection = await this.$CoreJs.salesmanSelection({
				agent_ids: this.searchForm.agent_ids,
				msm_ids: this.searchForm.msm_ids,
			});
		},
		async getAgentSelection() {
			this.agent_selection = await this.$MasterDataJs.getAgent();
		},
		async getDeliveryAddressSelection() {
			let response = await this.$CoreJs.deliveryAddressSelection({
				customer_ids: this.searchForm.customer_ids,
				customer_marking_ids: this.searchForm.customer_marking_ids,
			});
			this.delivery_address_selection = response;
		},
		async getWarehouseSelection() {
			this.warehouseSelection = await this.$CoreJs.warehouseSelection();
		},
	},
};
</script>

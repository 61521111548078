<template>
	<div class="d-flex">
		<b-button variant="info" size="sm" class="mr-1" :disabled="!show_view" v-if="hideView == 0" :to="view_path">
			<b-icon-info-square-fill></b-icon-info-square-fill>
			<span class="ml-1" v-text="$t('COMMON.BTN_VIEW')"></span>
		</b-button>
		<b-button variant="primary" size="sm" class="mr-1" :disabled="!show_edit" v-if="hideEdit == 0" :to="edit_path">
			<b-icon-pencil-square></b-icon-pencil-square>
			<span class="ml-1" v-text="$t('COMMON.BTN_EDIT')"></span>
		</b-button>
		<slot name="otherButtonSlot"></slot>
		<b-button variant="danger" size="sm" class="mr-1" :disabled="!show_delete" v-if="hideDelete == 0" @click="$emit('deleteData')">
			<b-icon-trash-fill></b-icon-trash-fill>
			<span class="ml-1" v-text="$t('COMMON.BTN_DELETE')"></span>
		</b-button>
	</div>
</template>

<script>
export default {
	name: "GimSummaryPage",
	props: {
		data: {
			type: Object,
			default() {
				return {};
			}
		},
		routeUrl: {
			type: String,
			default: null
		},
		idKey: {
			type: String,
			default: "id"
		},
		hideDelete: {
			type: Number,
			default: 0
		},
		hideEdit: {
			type: Number,
			default: 0
		},
		hideView: {
			type: Number,
			default: 0
		},
		id: {
			type: Number,
			default() {
				return 0;
			}
		},
		showView: null,
		showEdit: null,
		showDelete: null,
		params: null,
		to_name: null
	},
	computed: {
		show_view() {
			if (typeof this.showView == "boolean") return this.showView;
			else if (typeof this.showView == "number") return this.showView == 1;
		},
		show_edit() {
			if (typeof this.showEdit == "boolean") return this.showEdit;
			else if (typeof this.showEdit == "number") return this.showEdit == 1;
		},
		show_delete() {
			return this.showDelete || this.showDelete == 1;
			// if (typeof this.showDelete == "boolean") return this.showDelete;
			// else if (typeof this.showDelete == "number") return this.showDelete == 1;
		},
		view_path() {
			if (this.$CoreSettingsJs.emptyStringValidation(this.to_name)) return this.setRoute("detail");
			return { name: `${this.to_name}-detail`, params: this.params };
		},
		edit_path() {
			if (this.$CoreSettingsJs.emptyStringValidation(this.to_name)) return this.setRoute("edit");
			return { name: `${this.to_name}-edit`, params: this.params };
		}
	},
	methods: {
		setRoute(path) {
			let _path;
			if (this.id != 0) {
				_path = `${this.routeUrl}/${path}/${this.id}`;
			} else if (!this.$CoreSettingsJs.emptyObjectValidation(this.data)) {
				let _key = this.data;
				let nestedIdKey = this.idKey.split(".");
				console.log(nestedIdKey);
				for (var i = 0; i < nestedIdKey.length; i++) {
					_key = _key[nestedIdKey[i]];
				}
				_path = `${this.routeUrl}/${path}/${_key}`;
			} else {
				_path = null;
			}

			return _path;
		}
	}
};
</script>

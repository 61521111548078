import Vue from "vue";
import VueI18n from "vue-i18n";
import merge from "deepmerge";

// Localisation language list
import { locale as en_local } from "@/core/config/i18n/en";
import { locale as en_shared } from "@shared/src/core/config/i18n/en";
const en = merge(en_local, en_shared);

import { locale as ch_local } from "@/core/config/i18n/ch";
import { locale as ch_shared } from "@shared/src/core/config/i18n/ch";
const ch = merge(ch_local, ch_shared);
Vue.use(VueI18n);

console.log(en.EPO_MANAGEMENT);

let messages = {};
//messages = { ...messages, en, ch, es, jp, de, fr };
messages = { ...messages, en, ch };

// get current selected language
const lang = localStorage.getItem("language") || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: lang, // set locale
	messages // set locale messages,
});

export default i18n;

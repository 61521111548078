export const locale = {
	WEEKDAYS: {
		MONDAY: "星期一",
		TUESDAY: "星期二",
		WEDNESDAY: "星期三",
		THURSDAY: "星期四",
		FRIDAY: "星期五",
		SATURDAY: "星期六",
		SUNDAY: "星期日",
	},
	COMMON: {
		LBL_AGENT: "GBizs 代理",
		LBL_MOBILE_CONTACT_NO: "手机联系号码",
		LBL_MOBILE_CONTTACT_PIC: "手机联系人",

		LBL_ALT_MOBILE_CONTACT_NO: "手机联系号码 (Alt)",
		LBL_ALT_MOBILE_CONTTACT_PIC: "手机联系人 (Alt)",

		LBL_OFFICE_NO: "办公室联络号码 ",
		LBL_OFFICE_NO_PIC: "办公室联络人",
		LBL_SET_INVALID_PERSONNEL: "Invalid By",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_ACKNOWLEDGE_BY: "Acknowledge By",
		LBL_VERIFY_BY: "Verify By",
		LBL_ACTION_TIME: "Action Time",
		LBL_REFERENCE_NO: "单号",
		LBL_HISTORY_DATE: "修改日期",
		LBL_PARCEL_GUANRANTEE: "货物保障",

		LBL_FAX_NO: "传真号码",
		LBL_FAX_NO_PIC: "传真联系人",

		TXT_ALL: "全部",
		TOTAL_TITLE: "总额",
		SELECTION_OTHERS: "其他",
		PH_ENTER_TEXTBOX: "输入 {txt_fieldname}",
		PH_ENTER_DATE: "输入日期 ",
		PH_ENTER_DATE_FROM: "输入 {name} 从",
		PH_ENTER_DATE_TO: "输入 {name} 至",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT:
			"图片大小应小于1 MB,支持的格式为.jpg、.jpeg、.png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED:
			"图像格式不支持。支持的格式: .jpg, .jpeg, .png",
		MSG_SUPPORT_DOC_SIZE_FORMAT:
			"文档大小应小于10 MB",
		STATUS: {
			INACTIVE: "不活跃",
			ACTIVE: "活跃",
			SUSPEND: "暂停",
			YES: "是",
			NO: "不是",
			SAVE_AS_DRAFT: "草稿",
			UNVERIFIED: "未核实",
			WGI: "WGI",
			PENDING: "待定",
			APPROVED: "已审核",
			REJECTED: "已拒绝",
			VERIFY: "核实中",
			CLOSED: "已关闭",
			OPEN: "打开",
			ACKNOWLEDGE: "确认",
			NEW: "新"
		},
		MSG: {
			API_SERVER_DOWN: "服务器连接被拒绝。请联系系统管理员。",
			MSG_DATA_NOT_FOUND: "未找到记录",
			DATA_NOT_AVAILABLE: "无可用数据",
		},

		BTN_DOWNLOAD_ALL_AS_PDF: "全部下载为PDF",
		BTN_DOWNLOAD_AS_PDF: "下载为PDF",
		BTN_ENABLE_EDIT: "启用编辑 {name}",
		BTN_DISABLE_EDIT: "关闭编辑 {name}",
		BTN_REJECT: "拒绝",
		BTN_VERIFY: "核实",
	},
	EPO: {
		STATUS: {
			ACTIVE: "Active",
			INACTIVE: "Inactive",
		},
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "新建",
			UPDATE_TYPE: "更新",
		},
		STATUS: {
			PENDING: "待处理中",
			VERIFYING: "核查中",
			APPROVED: "已核准",
			REJECTED: "遭拒",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "保存为发货地址",
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			RECEIPT: "收据"
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",
			OTHER: "其他",
			PN: "PN",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "日期",
			SUPPLIER: "供应商",
			SUPPLIER_CODE: "供应商代码",
			REMARK: "备注",
			MOBILE: "手机",
			CONTACT_PERSON: "联系人",
			DOC_NO: "单号",
			PHONE_NO: "电话",
			PAYMENT_METHOD: "付款方式",
			ACCOUNT_NAME: "账户名",
			ACCOUNT_NO: "账号",
			BANK_ADDRESS: "开户行",
			BANK_NAME: "银行名"
		},
		CONTENT: {
			SERIAL_NO: "序号",
			CUSTOMER_MARKING: "客户代号",
			SO_NO: "订单号",
			CURRENCY: "币种",
			CTN: "件数",
			WEIGHT: "重量",
			DIMENSION: "体积",

			TOTAL_AMT: "总金额",
			DISCOUNT: "折扣",
			DEPOSIT: "订金",
			STICKER_FEE: "扣贴纸费",
			BALANCE_AMT: "余额",
			TOTAL: "总额"
		},
		FOOTER: {
			DELIVER: "送货人签名",
			PERSON_IN_CHARGES: "经手人",
			CONTACT_PERSON: "联系人",
			CONTACT_NO: "电话"
		}
	}
};

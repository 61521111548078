<template>
	<b-col cols="1" sm="4" md="4" lg="1" xl="1" class="pt-3" :order="order">
		<b-button variant="primary" size="sm" type="submit" class="text-nowrap" :class="$Common.isMobile() ? `btn-icon` : ``">
			<b-icon-search></b-icon-search>
			<span v-show="!$Common.isMobile()" v-text="$t('COMMON.BTN_SEARCH')"></span>
		</b-button>
	</b-col>
</template>

<script>
export default {
	name: "SearchButton",
	props: {
		hasPermission: false,
		order: 0
	}
};
</script>

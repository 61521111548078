<template>
	<div>
		<b-form-group :label="$t('COMMON.LBL_CREATED_BY')" v-if="creation_name != null">
			<b-form-input
				size="sm"
				:placeholder="$t('COMMON.PH_ENTER_CREATED_BY')"
				disabled
				v-model="creation_name"
			></b-form-input>
		</b-form-group>
		<b-form-group :label="$t('COMMON.LBL_CREATED_AT')" v-if="creation_on != null">
			<b-form-input
				type="datetime"
				size="sm"
				:placeholder="$t('COMMON.PH_ENTER_CREATED_AT')"
				disabled
				v-model="creation_on"
			></b-form-input>
		</b-form-group>
		<b-form-group :label="$t('COMMON.LBL_UPDATED_BY')" v-if="modified_by != null">
			<b-form-input
				size="sm"
				:placeholder="$t('COMMON.PH_ENTER_UPDATED_BY')"
				disabled
				v-model="modified_by"
			></b-form-input>
		</b-form-group>
		<b-form-group :label="$t('COMMON.LBL_UPDATED_AT')" v-if="modified_on != null">
			<b-form-input
				type="datetime"
				size="sm"
				:placeholder="$t('COMMON.PH_ENTER_UPDATED_AT')"
				disabled
				v-model="modified_on"
			></b-form-input>
		</b-form-group>
	</div>
</template>

<script>
export default {
	name: "CreationModified",
	props: {
		creation_on: null,
		modified_on: null,
		creation_name: null,
		modified_by: null
	}
};
</script>

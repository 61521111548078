const i18nService = {
	defaultLanguage: "en",

	languages: [
		{
			id: 1,
			lang: "en",
			name: "English",
			flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
		},
		{
			id: 2,
			lang: "ch",
			name: "Simplified Chinese",
			flag: process.env.BASE_URL + "media/svg/flags/034-china.svg"
		}
	],

	/**
	 * Keep the active language in the localStorage
	 * @param lang
	 */
	setActiveLanguage(lang) {
		localStorage.setItem("language", lang);
	},

    setActiveLocaleId(locale_id){
        localStorage.setItem("locale_id", locale_id);
    },

	/**
	 * Get the current active language
	 * @returns {string | string}
	 */
	getActiveLanguage() {
		return localStorage.getItem("language") || this.defaultLanguage;
	},
};

export default i18nService


import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import { SET_PERMISSION } from "@/core/services/store/config.module";
import router from '../../../router'
import i18n from "@/core/plugins/vue-i18n";

// action types
export const EMPTY_ERROR = "emptyError";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgotPassword";
export const FORGOT_PASSWORD_VERIFY_TOKEN = "forgotPasswordVerifyToken";
export const FORGOT_PASSWORD_UPDATE_PASSWORD = "forgotPasswordUpdatePassword";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const CLEAR_ERROR = "clearError";

const state = {
	errors: [],
	user: {
		id: null,
		name: null,
		permission: [],
		username: null,
		checker: 0,
		is_system_admin: 0,
		token: JwtService.getToken(),
	},
	isAuthenticated: !!JwtService.getToken()
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	}
};

const actions = {
	[EMPTY_ERROR](context) {
		context.commit(CLEAR_ERROR);
	},
	[LOGIN](context, credentials) {
		return new Promise(resolve => {
			ApiService.post("auth", credentials, true)
				.then(response => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(SET_AUTH, data.result);
						context.commit(SET_USER, data.result);
						context.commit(SET_PERMISSION, data.result.permission);
						resolve(data);
					} else if (data.code === 2001) {
						if (data.msg == "Invalid Username/Password") {
							context.commit(SET_ERROR, [i18n.t("AUTH.RESPONSE_MSG.LOGIN.2001")]);
						} else {
							context.commit(SET_ERROR, [data.msg]);
						}

					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				})
				.catch(({ response }) => {
					let data = response.data;
					context.commit(SET_ERROR, [data.msg]);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		return new Promise(resolve => {
			ApiService.post("login", credentials, true)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			return new Promise(resolve => {
				ApiService.post("verify")
					.then(response => {
						let data = response.data;
						if (data.code !== 200) {
							context.commit(PURGE_AUTH);
						} else {
							context.commit(SET_USER, data.result);
							context.commit(SET_PERMISSION, data.result.permission);
						}
						resolve(data);
					})
					.catch(() => {
						context.commit(PURGE_AUTH);
					})
					.finally(() => {
						if (!state.isAuthenticated) {
							router.push({ name: "login" });
						}
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_PASSWORD](context, payload) {
		const password = payload;

		return ApiService.put("password", password).then(({ data }) => {
			context.commit(SET_PASSWORD, data);
			return data;
		});
	},
	[FORGOT_PASSWORD](context, email) {
		return new Promise(resolve => {
			ApiService.post("forgot-password", email, true)
				.then(response => {
					let data = response.data;
					if (data.code === 200) {
						if (data.result.is_send === true) {
							Swal.fire({
								title: "",
								text: "Email has been sent to your email...",
								icon: "success",
								confirmButtonClass: "btn btn-secondary",
								heightAuto: false
							})

							resolve(data);
						} else {
							context.commit(SET_ERROR, [data.msg]);
						}
					} else {
						context.commit(SET_ERROR, [data.msg]);
					}
				})
				.catch(({ response }) => {
					let data = response.data;
					context.commit(SET_ERROR, [data.msg]);
				});
		});
	},
	[FORGOT_PASSWORD_VERIFY_TOKEN](context, vtoken) {
		return new Promise(resolve => {
			ApiService.post("forgot-password/verify", vtoken, true)
				.then(response => {
					let data = response.data;
					if (data.code === 200) {
						context.commit(PURGE_AUTH);
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	},
	[FORGOT_PASSWORD_UPDATE_PASSWORD](context, payload) {
		return new Promise(resolve => {
			ApiService.post("forgot-password/update", payload, true)
				.then(response => {
					let data = response.data;
					if (data.code == 200) {
						resolve(data);
					} else {
						context.commit(PURGE_AUTH);
						Swal.fire({
							title: "",
							text: data.msg,
							icon: "error",
							confirmButtonClass: "btn btn-secondary",
							heightAuto: false
						});
					}
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
					Swal.fire({
						title: "",
						text: "Cannot connect to server!",
						icon: "error",
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					});
				});
		});
	}
};

const mutations = {
	[CLEAR_ERROR](state) {
		state.errors = {};
	},
	[SET_USER](state, user) {
		state.user.id = user.id;
		state.user.name = user.name;
		state.user.username = user.username;
		state.user.permission = user.permission;
		state.user.checker = user.checker;
		state.user.is_system_admin = user.is_system_admin;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, user) {
		state.isAuthenticated = true;
		state.user.token = user.token;
		state.errors = {};
		JwtService.saveToken(state.user.token);
	},
	[SET_PASSWORD](state, password) {
		state.user.password = password;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		JwtService.destroyToken();
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
